import type { Ref } from "vue";
import type { NewsletterSignUpDto } from "~/modules/shared/composables/useNewsletterSignUp/useNewsletterSignUp.types";

export const useNewsletterSignUp = () => {
  const apiUrl = useStrapiUrl();

  const error: Ref<string | null> = ref(null);
  const loading = ref(false);
  const success = ref(false);

  const submitNewsletterSignUp = async (data: NewsletterSignUpDto) => {
    success.value = false;
    error.value = null;

    try {
      loading.value = true;
      // Using useFetch instead of strapi client because for some reason the latter one does not return status code
      const { error } = await useFetch(`${apiUrl}/newsletter`, {
        method: "POST",
        body: {
          data,
        },
      });

      if (error.value) throw error;

      loading.value = false;
      success.value = true;
    } catch (err) {
      loading.value = false;
      throw err;
    }
  };

  return { error, loading, success, submitNewsletterSignUp };
};
