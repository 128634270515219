<template>
  <div class="form__container">
    <slot />

    <footer class="form__footer">
      <slot name="footer" />

      <div
        v-if="$slots['error-message']"
        class="message__container message__container--error"
      >
        <slot name="error-message" />
      </div>

      <div
        v-if="$slots['success-message']"
        class="message__container message__container--success"
      >
        <slot name="success-message" />
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.form__container {
  @apply relative flex flex-col;

  :deep {
    .input__container {
      @apply z-10;
    }
  }

  .message__container {
    @apply absolute left-0 text-body-xxs z-0;
    animation-name: slideDown;
    animation-duration: 5s;
    animation-fill-mode: forwards;

    &--error {
      @apply text-error-500;
    }

    &--success {
      @apply text-success-500;
    }
  }
}

@keyframes slideDown {
  0% {
    @apply bottom-2 opacity-0;
  }

  8% {
    @apply -bottom-14 opacity-100;
  }

  92% {
    @apply -bottom-14 opacity-100;
  }

  100% {
    @apply bottom-2 opacity-0;
  }
}
</style>
